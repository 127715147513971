*, ::before, ::after {
    --tw-border-spacing-x: 0;
    --tw-border-spacing-y: 0;
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    --tw-pan-x:  ;
    --tw-pan-y:  ;
    --tw-pinch-zoom:  ;
    --tw-scroll-snap-strictness: proximity;
    --tw-ordinal:  ;
    --tw-slashed-zero:  ;
    --tw-numeric-figure:  ;
    --tw-numeric-spacing:  ;
    --tw-numeric-fraction:  ;
    --tw-ring-inset:  ;
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: rgb(59 130 246 / 0.5);
    --tw-ring-offset-shadow: 0 0 #0000;
    --tw-ring-shadow: 0 0 #0000;
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
    --tw-blur:  ;
    --tw-brightness:  ;
    --tw-contrast:  ;
    --tw-grayscale:  ;
    --tw-hue-rotate:  ;
    --tw-invert:  ;
    --tw-saturate:  ;
    --tw-sepia:  ;
    --tw-drop-shadow:  ;
    --tw-backdrop-blur:  ;
    --tw-backdrop-brightness:  ;
    --tw-backdrop-contrast:  ;
    --tw-backdrop-grayscale:  ;
    --tw-backdrop-hue-rotate:  ;
    --tw-backdrop-invert:  ;
    --tw-backdrop-opacity:  ;
    --tw-backdrop-saturate:  ;
    --tw-backdrop-sepia:  
}
::backdrop {
    --tw-border-spacing-x: 0;
    --tw-border-spacing-y: 0;
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    --tw-pan-x:  ;
    --tw-pan-y:  ;
    --tw-pinch-zoom:  ;
    --tw-scroll-snap-strictness: proximity;
    --tw-ordinal:  ;
    --tw-slashed-zero:  ;
    --tw-numeric-figure:  ;
    --tw-numeric-spacing:  ;
    --tw-numeric-fraction:  ;
    --tw-ring-inset:  ;
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: rgb(59 130 246 / 0.5);
    --tw-ring-offset-shadow: 0 0 #0000;
    --tw-ring-shadow: 0 0 #0000;
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
    --tw-blur:  ;
    --tw-brightness:  ;
    --tw-contrast:  ;
    --tw-grayscale:  ;
    --tw-hue-rotate:  ;
    --tw-invert:  ;
    --tw-saturate:  ;
    --tw-sepia:  ;
    --tw-drop-shadow:  ;
    --tw-backdrop-blur:  ;
    --tw-backdrop-brightness:  ;
    --tw-backdrop-contrast:  ;
    --tw-backdrop-grayscale:  ;
    --tw-backdrop-hue-rotate:  ;
    --tw-backdrop-invert:  ;
    --tw-backdrop-opacity:  ;
    --tw-backdrop-saturate:  ;
    --tw-backdrop-sepia:  
}
.container {
    width: 100%
}
@media (min-width: 640px) {
    .container {
        max-width: 640px
    }
}
@media (min-width: 768px) {
    .container {
        max-width: 768px
    }
}
@media (min-width: 1024px) {
    .container {
        max-width: 1024px
    }
}
@media (min-width: 1280px) {
    .container {
        max-width: 1280px
    }
}
@media (min-width: 1536px) {
    .container {
        max-width: 1536px
    }
}
.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0
}
.pointer-events-none {
    pointer-events: none
}
.static {
    position: static
}
.fixed {
    position: fixed
}
.absolute {
    position: absolute
}
.relative {
    position: relative
}
.inset-y-0 {
    top: 0px;
    bottom: 0px
}
.left-0 {
    left: 0px
}
.mb-2 {
    margin-bottom: 0.5rem
}
.mt-1 {
    margin-top: 0.25rem
}
.mt-8 {
    margin-top: 2rem
}
.mr-3 {
    margin-right: 0.75rem
}
.mr-2 {
    margin-right: 0.5rem
}
.ml-2 {
    margin-left: 0.5rem
}
.mt-4 {
    margin-top: 1rem
}
.block {
    display: block
}
.flex {
    display: flex
}
.inline-flex {
    display: inline-flex
}
.table {
    display: table
}
.grid {
    display: grid
}
.h-full {
    height: 100%
}
.h-12 {
    height: 3rem
}
.h-2\.5 {
    height: 0.625rem
}
.h-2 {
    height: 0.5rem
}
.h-5 {
    height: 1.25rem
}
.h-\[300px\] {
    height: 300px
}
.h-4 {
    height: 1rem
}
.min-h-\[160px\] {
    min-height: 160px
}
.min-h-\[200px\] {
    min-height: 200px
}
.w-12 {
    width: 3rem
}
.w-48 {
    width: 12rem
}
.w-40 {
    width: 10rem
}
.w-36 {
    width: 9rem
}
.w-full {
    width: 100%
}
.w-5 {
    width: 1.25rem
}
.w-1\/4 {
    width: 25%
}
.w-4 {
    width: 1rem
}
.w-3\/4 {
    width: 75%
}
.w-80 {
    width: 20rem
}
.flex-1 {
    flex: 1 1 0%
}
.flex-grow {
    flex-grow: 1
}
.grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr))
}
.flex-col {
    flex-direction: column
}
.items-center {
    align-items: center
}
.justify-center {
    justify-content: center
}
.gap-4 {
    gap: 1rem
}
.gap-8 {
    gap: 2rem
}
.overflow-scroll {
    overflow: scroll
}
.overflow-x-auto {
    overflow-x: auto
}
.whitespace-nowrap {
    white-space: nowrap
}
.rounded {
    border-radius: 0.25rem
}
.rounded-full {
    border-radius: 9999px
}
.rounded-lg {
    border-radius: 0.5rem
}
.rounded-tr {
    border-top-right-radius: 0.25rem
}
.border-l-2 {
    border-left-width: 2px
}
.border-b {
    border-bottom-width: 1px
}
.border-none {
    border-style: none
}
.border-amber-400 {
    --tw-border-opacity: 1;
    border-color: rgb(251 191 36 / var(--tw-border-opacity))
}
.border-gray-300 {
    --tw-border-opacity: 1;
    border-color: rgb(209 213 219 / var(--tw-border-opacity))
}
.bg-slate-200 {
    --tw-bg-opacity: 1;
    background-color: rgb(226 232 240 / var(--tw-bg-opacity))
}
.bg-slate-100 {
    --tw-bg-opacity: 1;
    background-color: rgb(241 245 249 / var(--tw-bg-opacity))
}
.bg-amber-50 {
    --tw-bg-opacity: 1;
    background-color: rgb(255 251 235 / var(--tw-bg-opacity))
}
.bg-white {
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity))
}
.bg-gray-50 {
    --tw-bg-opacity: 1;
    background-color: rgb(249 250 251 / var(--tw-bg-opacity))
}
.bg-gray-100 {
    --tw-bg-opacity: 1;
    background-color: rgb(243 244 246 / var(--tw-bg-opacity))
}
.p-4 {
    padding: 1rem
}
.p-2 {
    padding: 0.5rem
}
.px-2 {
    padding-left: 0.5rem;
    padding-right: 0.5rem
}
.px-4 {
    padding-left: 1rem;
    padding-right: 1rem
}
.py-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem
}
.px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem
}
.py-3 {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem
}
.py-4 {
    padding-top: 1rem;
    padding-bottom: 1rem
}
.pl-4 {
    padding-left: 1rem
}
.pl-3 {
    padding-left: 0.75rem
}
.pl-10 {
    padding-left: 2.5rem
}
.text-left {
    text-align: left
}
.text-right {
    text-align: right
}
.text-base {
    font-size: 1rem;
    line-height: 1.5rem
}
.text-sm {
    font-size: 0.875rem;
    line-height: 1.25rem
}
.text-xs {
    font-size: 0.75rem;
    line-height: 1rem
}
.font-semibold {
    font-weight: 600
}
.font-medium {
    font-weight: 500
}
.uppercase {
    text-transform: uppercase
}
.text-slate-100 {
    --tw-text-opacity: 1;
    color: rgb(241 245 249 / var(--tw-text-opacity))
}
.text-gray-900 {
    --tw-text-opacity: 1;
    color: rgb(17 24 39 / var(--tw-text-opacity))
}
.text-slate-400 {
    --tw-text-opacity: 1;
    color: rgb(148 163 184 / var(--tw-text-opacity))
}
.text-gray-500 {
    --tw-text-opacity: 1;
    color: rgb(107 114 128 / var(--tw-text-opacity))
}
.text-gray-700 {
    --tw-text-opacity: 1;
    color: rgb(55 65 81 / var(--tw-text-opacity))
}
.text-blue-600 {
    --tw-text-opacity: 1;
    color: rgb(37 99 235 / var(--tw-text-opacity))
}
.text-gray-400 {
    --tw-text-opacity: 1;
    color: rgb(156 163 175 / var(--tw-text-opacity))
}
.no-underline {
    text-decoration-line: none
}
.opacity-20 {
    opacity: 0.2
}
.filter {
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow)
}
.hover\:bg-gray-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(243 244 246 / var(--tw-bg-opacity))
}
.hover\:bg-gray-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(249 250 251 / var(--tw-bg-opacity))
}
.hover\:text-gray-700:hover {
    --tw-text-opacity: 1;
    color: rgb(55 65 81 / var(--tw-text-opacity))
}
.hover\:underline:hover {
    text-decoration-line: underline
}
.focus\:ring-blue-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(59 130 246 / var(--tw-ring-opacity))
}
@media (min-width: 768px) {
    .md\:grid-cols-2 {
        grid-template-columns: repeat(2, minmax(0, 1fr))
    }
}
@media (min-width: 1024px) {
    .lg\:grid-cols-3 {
        grid-template-columns: repeat(3, minmax(0, 1fr))
    }
}
@media (min-width: 1280px) {
    .xl\:grid-cols-4 {
        grid-template-columns: repeat(4, minmax(0, 1fr))
    }
}
