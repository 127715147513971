.swagger-ui .info a {
  color: #3c5161;
}

.swagger-ui .btn.authorize {
  border-color: #f8bd41;
  color: #3c5161;
  background-color: #f8bd41;
}

.swagger-ui .scheme-container {
  background: #e8eef0;
}

.swagger-ui .btn.authorize svg {
  fill: #3c5161;
}

.swagger-ui .opblock.opblock-get {
  border-color: #3c5161;
}

.swagger-ui .opblock.opblock-get .opblock-summary-method {
  background: #f8bd41;
  color: #3c5161;
}

.swagger-ui .opblock.opblock-get .opblock-summary {
  border-color: #f8bd41;
}

.swagger-ui .opblock.opblock-get {
  background: #e8eef0;
}

.swagger-ui section.models .model-container {
  background: #a4bcc2;
}

.swagger-ui section.models .model-container:hover {
  background: #e8eef0;
}
